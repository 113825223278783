<template>
  <b-card>
    <b-row v-if="can('read/home')">
      <b-col cols="12" xl="4" class="pl-2 mb-2">
        <!-- card -->
        <div
          class="border rounded text-center mt-2 p-2 cursor-pointer"
          @click="redirectToManajemenSppd('approve1')"
        >
          <h5 class="mb-1">Perlu Approval 1</h5>
          <p>{{ model.need_approval_1 }}</p>
        </div>
      </b-col>
      <b-col cols="12" xl="4" class="pl-2 mb-2">
        <!-- card -->
        <div
          class="border rounded text-center mt-2 p-2 cursor-pointer"
          @click="redirectToManajemenSppd('approve2')"
        >
          <h5 class="mb-1">Perlu Approval 2</h5>
          <p>{{ model.need_approval_2 }}</p>
        </div>
      </b-col>
      <b-col cols="12" xl="4" class="pl-2 mb-2">
        <!-- card -->
        <div
          class="border rounded text-center mt-2 p-2 cursor-pointer"
          @click="redirectToManajemenSppd('lengkap')"
        >
          <h5 class="mb-1">Approval Lengkap</h5>
          <p>{{ model.complete }}</p>
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <div class="col-12 p-2 p-sm-3 mb-4 mt-3">
        <div class="w-100 text-center">
          <h2 class="mb-1">Selamat Datang</h2>
          <p class="mb-2">di website SPPD POS</p>
        </div>
      </div>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard } from 'bootstrap-vue'
import axios from '@axios'

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  data() {
    return {
      config: {
        api: '/dashboard/counter',
      },
      model: {
        need_approval_1: 0,
        need_approval_2: 0,
        complete: 0,
      },
    }
  },
  mounted() {
    let _ = this
    _.getData()
  },
  methods: {
    getData() {
      let _ = this
      axios.get(_.config.api).then(response => {
        let _ = this
        _.model = response.data.data
      })
    },
    redirectToManajemenSppd(activeTab) {
      this.$router.push({
        name: 'manajemen-sppd',
        params: { activeTab },
      })
    },
  },
}
</script>

<style>
.cursor-pointer {
  cursor: pointer;
}
</style>
